import React, { useState, useEffect, useCallback, useRef } from 'react';
import { navigate } from "gatsby";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@mui/material/useMediaQuery';
import Layout from '../components/layout';
import FileUploadButton from '../components/FileUploadButton';
import { gFileInUploadProcess } from '../components/FileUploadButton';
import ContantTable from '../components/ContantTable';
import Stack from '@mui/material/Stack';
import { ClearToken, ClearTokenAndCache, IsTokenExpired } from "../store/store";
import { videoStorageAndUSageFramework } from "../services/file_service/frameworks/videoStorageAndUSageFramework";
import { IFetcherFramework } from "../services/file_service/entities/IFetcherFramework";
import { IDataManipulatorFramework } from "../services/file_service/entities/IDataManipulatorFramework";
import { AddRemoveAndChangeVideoStatusUseCase } from "../services/file_service/use_cases/AddRemoveAndChangeVideoStatusUseCase";
import { OnFileUploadHandler } from "../PageUtilities/OnFileUpload";
import LoadingCircle from "../components/dashboardComponents/LoadingCircle";
import isObjectValid from "../utils/isObjectValid";
import { IsServerMetricsCapable } from "../utils/capabilities";
import { VideoStorageAndUsage } from "../services/file_service/entities/VideoStorageAndUsage";
import axios from "../projectAxios";
import CancellationNotification from '../components/CancellationNotification';

const PROCESSING_STATUS = 0;
const DELETE_DURING_PROCESSING_WILL_DELETE_IN_PROCESS_STATUS = 29;
const PENDING_UPLOAD_STATUS = 34;
const UPDATE_INTERVAL = 600000; //10 minutes  10000; // 10 seconds

const Content = () => {
  const dispatch = useDispatch();
  const isBigScreen = useMediaQuery('(min-width:680px)');
  
  // Redux state
  const { isLoggedIn, token, userInformation, videoUsageStorage } = useSelector((state) => ({
    isLoggedIn: state?.isLoggedIn || false,
    token: state?.token || null,
    userInformation: state?.userInformation || null,
    videoUsageStorage: state?.videoUsageStorage || null,
  }));

  // Local state
  const [fileNeedsToBeUploaded, setFileNeedsToBeUploaded] = useState(null);
  const [isUserInformationLoading, setIsUserInformationLoading] = useState(true);
  const [isLoadingSomeOperation, setIsLoadingSomeOperation] = useState(false);
  const [canceledVideos, setCanceledVideos] = useState(new Set());
  const [dismissedNotifications, setDismissedNotifications] = useState(new Set());

  // Refs
  const lastUpdateTimeRef = useRef(Date.now());
  const updateIntervalRef = useRef(null);
  const deletionInProgressRef = useRef(new Set());

  const initializeFrameworks = useCallback(() => {
    const helper = new videoStorageAndUSageFramework(token);
    return {
      usageAndStorageFramework: new IFetcherFramework(helper),
      dataManipulator: new IDataManipulatorFramework(helper)
    };
  }, [token]);

  // Check if there are any videos in processing or pending status
  const hasActiveUploads = useCallback(() => {
    if (!videoUsageStorage?.VideoList) return false;
    
    return videoUsageStorage.VideoList.some(video => 
      video.Status === PROCESSING_STATUS || 
      video.Status === PENDING_UPLOAD_STATUS
    );
  }, [videoUsageStorage]);

  const fetchLatestContent = useCallback(async () => {
    if (!token) return;

    try {
      const { usageAndStorageFramework } = initializeFrameworks();
      const hasChanges = await usageAndStorageFramework.FetchFromServerIgnoreCache(dispatch);

      if (videoUsageStorage?.VideoList) {
        const currentCanceled = new Set(
          videoUsageStorage.VideoList
            .filter(video => video.Status === DELETE_DURING_PROCESSING_WILL_DELETE_IN_PROCESS_STATUS)
            .map(video => video.MasterLink)
        );

        setDismissedNotifications(prev => {
          const newDismissed = new Set(prev);
          for (const dismissedId of prev) {
            if (!currentCanceled.has(dismissedId)) {
              newDismissed.delete(dismissedId);
            }
          }
          return newDismissed;
        });

        setCanceledVideos(currentCanceled);
      }

      if (hasChanges) {
        lastUpdateTimeRef.current = Date.now();
      }
    } catch (error) {
      console.error('Error fetching latest content:', error);
      if (error.response?.status === 401 || error.response?.status === 403) {
        handleUnauthorized();
      }
    }
  }, [token, dispatch, initializeFrameworks, videoUsageStorage]);

  const handleNotificationClose = useCallback((videoLink) => {
    setDismissedNotifications(prev => new Set([...prev, videoLink]));
  }, []);

  const extractMovieId = useCallback((link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link.split("/")[4]
      : link.split("/")[2];
  }, []);

  const changeStatusOfASingleVideo = useCallback((link, status) => {
    if (!videoUsageStorage?.VideoList?.length) return;

    const addRemChange = new AddRemoveAndChangeVideoStatusUseCase(null);
    const videoObj = videoUsageStorage.VideoList.find(element => element.MasterLink === link);
    
    if (videoObj) {
      const updatedObj = { ...videoObj, Status: status };
      addRemChange.ChangeStatusOfVideo(updatedObj, videoUsageStorage, dispatch);
    }
  }, [videoUsageStorage, dispatch]);

  const deleteVideo = useCallback(async (movieId, link) => {
    if (!movieId || !token) return null;

    try {
      const data = new FormData();
      data.append('movieId', movieId);
      
      await axios.post("/MovieUpload/Delete", data, { 
        headers: { Authenticate: token }
      });
      
      return true;
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        handleUnauthorized();
      } else if (err.response?.status === 400) {
        changeStatusOfASingleVideo(link, 23);
      }
      return false;
    }
  }, [token, changeStatusOfASingleVideo]);

  const onFIleDeleteHandler = useCallback(async (rows, selected) => {
    if (!rows.length || !selected.length || !videoUsageStorage?.VideoList?.length)
    {
      //console.log("we don't send this delete request");
      return;
    }

    setIsLoadingSomeOperation(true);
    const successfullyDeleted = [];
    const deletePromises = [];

    for (const row of rows) {
      if (!selected.includes(row.lineId)) continue;
      
      const movieId = extractMovieId(row.link);
      if (deletionInProgressRef.current.has(movieId)) continue;

      deletionInProgressRef.current.add(movieId);
      
      const deletePromise = deleteVideo(movieId, row.link)
        .then(success => {
          if (success) {
            successfullyDeleted.push(
              new VideoStorageAndUsage(movieId, 0, 0, 0, "", "", 22, 0, Date.now(), 0, 0)
            );
          }
        })
        .finally(() => {
          deletionInProgressRef.current.delete(movieId);
        });

      deletePromises.push(deletePromise);
    }

    try {
      await Promise.all(deletePromises);

      if (successfullyDeleted.length > 0) {
        const addRemChange = new AddRemoveAndChangeVideoStatusUseCase(null);
        //addRemChange.RemoveVideoList(successfullyDeleted, videoUsageStorage, dispatch);

        // Change this to switch status to 22 (pending deleted)
        addRemChange.ChangeListStatusOfVideo(successfullyDeleted, videoUsageStorage, dispatch);
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    } finally {
      setIsLoadingSomeOperation(false);
    }
  }, [videoUsageStorage, dispatch, deleteVideo, extractMovieId]);

  const renderCancelNotifications = useCallback(() => {
    if (!videoUsageStorage?.VideoList) return null;

    return videoUsageStorage.VideoList
      .filter(video => 
        canceledVideos.has(video.MasterLink) && 
        !dismissedNotifications.has(video.MasterLink)
      )
      .map(video => (
        <CancellationNotification
          key={video.MasterLink}
          videoName={video.FileName || 'Unnamed video'}
          onClose={() => handleNotificationClose(video.MasterLink)}
        />
      ));
  }, [videoUsageStorage, canceledVideos, dismissedNotifications, handleNotificationClose]);

  const handleFileUpload = useCallback((files) => {
    if (!files) return;

    //console.log("handleFileUpload - this is the file that should be shown: ", files);
    setFileNeedsToBeUploaded(files);
  }, []);

  const handleUnauthorized = useCallback(() => {
    if (gFileInUploadProcess === 0) {
      dispatch(ClearToken());
      navigate("/login");
    }
  }, [dispatch]);

  const getUserInfo = useCallback((info) => ({
    firstName: isObjectValid(info) ? JSON.parse(info.data).firstName : null,
    encryption: isObjectValid(info) ? JSON.parse(info.data).encryption === true : false,
    serverMetrics: isObjectValid(info) ? IsServerMetricsCapable(JSON.parse(info.data).capabilities) : false
  }), []);

  // Initial setup
  useEffect(() => {
    if (!isLoggedIn || !token) {
      if (gFileInUploadProcess === 0) {
        navigate("/login");
        return;
      }
    }

    const initializeData = async () => {
      const { usageAndStorageFramework, dataManipulator } = initializeFrameworks();
      new AddRemoveAndChangeVideoStatusUseCase(dataManipulator);

      if (!videoUsageStorage) {
        await usageAndStorageFramework.FetchFromServer(dispatch);
      }
      setIsUserInformationLoading(false);
    };

    initializeData();
  }, [isLoggedIn, token, dispatch, initializeFrameworks, videoUsageStorage]);

  // File upload processing
  useEffect(() => {
    if (fileNeedsToBeUploaded && fileNeedsToBeUploaded !== null) {

      //console.log("useEffect-fileNeedsToBeUploaded: fileNeedsToBeUploaded: ", fileNeedsToBeUploaded);
      const handeledId = OnFileUploadHandler(
        isUserInformationLoading,
        fileNeedsToBeUploaded,
        videoUsageStorage,
        dispatch,
        null
      );

      setFileNeedsToBeUploaded(null);      
    }
  }, [fileNeedsToBeUploaded, isUserInformationLoading, videoUsageStorage, dispatch]);

  // Periodic updates
  useEffect(() => {
    const checkAndUpdate = () => {
      //console.log("In checkAndUpdate interval");
      if (hasActiveUploads()) {
        //console.log("In checkAndUpdate interval - there is active upload - going for status update from server");
        fetchLatestContent();
      }
    };

    updateIntervalRef.current = setInterval(checkAndUpdate, UPDATE_INTERVAL);

    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [hasActiveUploads, fetchLatestContent]);

  // Token expiration check
  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      if (gFileInUploadProcess === 0) {
        dispatch(IsTokenExpired());
      }
    }, 60000);

    return () => clearInterval(tokenCheckInterval);
  }, [dispatch]);

  // Cleanup dismissed notifications
  useEffect(() => {
    return () => {
      setDismissedNotifications(new Set());
    };
  }, []);

  const userInfo = getUserInfo(userInformation);

  return (
    <>
      <Helmet>
        <title>Content</title>
      </Helmet>

      <Layout 
        userName={userInfo.firstName} 
        canReadServerMetrics={userInfo.serverMetrics}
      >
        <Stack spacing={3} style={!isBigScreen ? { height: "700vh" } : null}>
          {renderCancelNotifications()}
          
          <FileUploadButton
            token={token}
            onLoadHandler={handleFileUpload}
            onNotAuthorized={handleUnauthorized}
            shouldBeEncrypted={userInfo.encryption}
          />

          {isLoadingSomeOperation ? (
            <LoadingCircle />
          ) : (
            <ContantTable 
              videoUsageStorage={videoUsageStorage}
              isLoading={isUserInformationLoading}
              onFIleDeleteHandler={onFIleDeleteHandler}
            />
          )}
        </Stack>
      </Layout>
    </>
  );
};

export default Content;