import React from "react";

import { Typography, Paper, Stack } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress} from '@mui/material';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';

import { formatBytes } from "../utils/formatNumbers";
import { fDate } from "../utils/formatDates";

// Handle the search part
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


// Add a safe date formatting function
/*const safeDateFormat = (dateValue) => {
  if (!dateValue) return '-';
  
  try {
    // First check if the date is valid
    const date = new Date(dateValue);
    if (isNaN(date.getTime())) {
      return '-';
    }
    return fDate(date);
  } catch (error) {
    console.warn('Invalid date value:', dateValue);
    return '-';
  }
};*/

const getNumericalRepresentation = (val, orderBy) =>
{
  if ("uploadDate" === orderBy)
  {
    return Date.parse(val);
  }

  return val;
}

// Modify the getNumericalRepresentation function to safely handle dates
/*const getNumericalRepresentation = (val, orderBy) => {
  if (orderBy === "uploadDate") {
    try {
      const date = new Date(val);
      return isNaN(date.getTime()) ? 0 : date.getTime();
    } catch (error) {
      return 0;
    }
  }
  return val || 0; // Return 0 for null/undefined values
};*/

function descendingComparator(a, b, orderBy) {
    const first = getNumericalRepresentation(a[orderBy], orderBy);
    const second = getNumericalRepresentation(b[orderBy], orderBy);

    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return  order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const GetHeadCells = () =>
{
    return [
        {
          id: 'status',
          numeric: true,
          disablePadding: false,
          label: 'Status',
        },
        {
          id: 'fileName',
          numeric: false,
          disablePadding: false,
          label: 'File Name',
        },
        {
          id: 'size',
          numeric: true,
          disablePadding: false,
          label: 'Size',
        },
        {
          id: 'uploadDate',
          numeric: true,
          disablePadding: false,
          label: 'Upload Date',
        },
        {
          id: 'monthlyBandwidth',
          numeric: true,
          disablePadding: false,
          label: 'Monthly Bandwidth',
          last: true
        },
        {
          id: 'TotalViews',
          numeric: true,
          disablePadding: false,
          label: 'Total Views',
          last: true
        },
        {
          id: 'ViewsPrecent',
          numeric: true,
          disablePadding: false,
          label: 'Views Percent',
          last: true
        },
      ];
}


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {GetHeadCells().map((headCell) => (
            <TableCell
             style={headCell.last !== undefined ? {paddingRight:10} : null} 
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const StatusCodeToReadableStatus = {
    0: "Processing",
    1: "Ready",
    6: "Bad file format",
    22: "Pending Delete",
    34: "Pending Upload",
    29: "Upload Canceled"
};

const GetReadableStatusCode = (status) =>
{
    const stringRep = StatusCodeToReadableStatus[status];
    if (stringRep === null || stringRep === undefined)
    {
        return status;
    }

    return stringRep;
}


function DeleteAlertDialog(open, setOpen, setAgree) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAfterOkClicked = () => {
    setAgree();
    setOpen(false);
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete these files?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting these files will permanently remove them from your account and cannot be undone. 
  Are you sure you want to proceed with the deletion?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleCloseAfterOkClicked}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
}

function isNullOrWhitespace(str) {
  return !str || !str.trim();
}


// Single source of filtering logic
const filterRows = (rows, searchLine) => {
  //console.log("rows before filtering:", rows);
  if (!Array.isArray(rows)) {
    console.warn('Expected rows to be an array, got:', rows);
    return [];
  }

  // First filter out status 29 and 22 status
  let filteredRows = rows.filter(item => item.status !== 29 && item.status !== 22);

  // Then apply search filter if needed
  if (!isNullOrWhitespace(searchLine)) {
    filteredRows = filteredRows.filter(item => 
      item?.fileName?.toLowerCase().includes(searchLine.toLowerCase())
    );
  }

  //console.log("res filtering:", filteredRows);

  return filteredRows;
};


const SortableTable = (props) =>
{
    const rows = filterRows(props.rows, props.searchLine); //props.rows;
    const onRowClick = props.onRowClick;
    const onDeleteClick = props.onFIleDeleteHandler; // onDeleteClick(rows, selected);
    const searchLine = props.searchLine;
    const parentHandleSearchChange = props.onSearchLineChange;

    const openDeleteDialog = props.openDeleteDialog;
    const selected = props.selected;
    const setOpenDeleteDialog = props.setOpenDeleteDialog;
    const setSelected = props.setSelected;
    
    

    const handleSearchChange = (event) => 
    {
      //console.log("Call with: ", event);
      parentHandleSearchChange(event);
    }

    const HandleDeleteRequest = () =>
    {
      // propogate delete request outside to proper handler
      //console.log("[HandleDeleteRequest] - clicked ok");
      onDeleteClick(rows, selected);
      setSelected([]);
    }

   
      
      function EnhancedTable(onRowClick, selected, setSelected, dense) {
        const [order, setOrder] = React.useState('desc');
        const [orderBy, setOrderBy] = React.useState('uploadDate');
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(5);
      
        const handleRequestSort = (event, property) => {
          const isAsc = orderBy === property && order === 'asc';
          setOrder(isAsc ? 'desc' : 'asc');
          setOrderBy(property);
        };
      
        const handleSelectAllClick = (event) => {
          const allSelected = rows.filter((n) => n.status !== 0).map((n) => n.lineId);
          if (selected.length === allSelected.length)
          {
            // we already in select - this time to unselect
            setSelected([]);
          }
          else 
          {
            setSelected(allSelected);
          }
          /* // from some reason this stop working in the current method
          if (event.target.checked) {
            const newSelecteds = rows.filter((n) => n.status !== 0).map((n) => n.lineId);
            setSelected(newSelecteds);
            return;
          }

          // clear
          setSelected([]);*/
        };
      

        

        const handleClick = (event, name) => {
          const selectedIndex = selected.indexOf(name);
          let newSelected = [];
          
          // If it's not exists in selected tble - add it
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
          } 
          // If it all ready in the selected table - remove it (can be at the start/end/middle of list)
          else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1),
            );
          }
      
          setSelected(newSelected);
        };
      
        const handleChangePage = (event, newPage) => {
          setPage(newPage);
        };
      
        const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        };
      
        
      
        const isSelected = (name) => selected.indexOf(name) !== -1;
      
        // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows =
          page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

        return (
          <>
              {DeleteAlertDialog(openDeleteDialog, setOpenDeleteDialog, HandleDeleteRequest)}
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                       rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.lineId);
                        const labelId = `enhanced-table-checkbox-${index}`;
      
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.lineId}
                            selected={isItemSelected}
                            style={row.status === 1 ? {cursor: "pointer"} : { color: "gray" }}
                          >
                            {row.status === 0 ? 
                            <TableCell padding="checkbox" >
                              <Checkbox
                                color="primary"
                                disabled
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                             : 
                            <TableCell onClick={(event) => handleClick(event, row.lineId)} padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>}
                            
                            
                            <TableCell
                              onClick={() => onRowClick(row.link, row.status)}
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              {row.status === 0 ? <CircularProgress size={20} /> : GetReadableStatusCode(row.status)}
                            </TableCell>
                            <TableCell align="left" onClick={() => onRowClick(row.link, row.status)}>{row.fileName}</TableCell>
                            <TableCell align="left" onClick={() => onRowClick(row.link, row.status)}>{formatBytes(row.size)}</TableCell>
                            <TableCell align="left" onClick={() => onRowClick(row.link, row.status)}>{fDate(row.uploadDate)}</TableCell>
                            <TableCell style={{paddingRight:10}} align="left" onClick={() => onRowClick(row.link, row.status)}>{formatBytes(row.monthlyBandwidth)}</TableCell>
                            <TableCell align="left" onClick={() => onRowClick(row.link, row.status)}>{row.TotalViews}</TableCell>
                            <TableCell align="left" onClick={() => onRowClick(row.link, row.status)}>{row.ViewsPrecent}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </>
        );
      }

    return EnhancedTable(onRowClick, selected, setSelected, props.dense);
}


const SortableTableWrapper = (props) => {
  const [dense, setDense] = React.useState(false);
  const [searchLine, setSearchLine] = React.useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  

  const handleSearchChange = (event) =>
  {
    setSearchLine(event.target.value);
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <>
          <Typography
              sx={{ flex: '1 1 100%' }}
              style={{color:"#757575"}}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              List of videos
            </Typography>
          </>
          
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => setOpenDeleteDialog(true)}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        ) : (
          null
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };


  function isNullOrWhitespace(str) {
    return !str || !str.trim();
  }

  const getFilteredRows = () =>
  {
    if (searchLine === null || searchLine === '' || isNullOrWhitespace(searchLine) === true)
    {
      return props.rows;
    }

    // filter the rows
    return props.rows.filter((item) =>
      item.fileName.toLowerCase().includes(searchLine.toLowerCase())
    );
  }

  return (
    <Box sx={{ width: '100%' }}>  
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Stack spacing={2}>
          <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", paddingTop:2 }}>
            <TextField
                  type="text"
                  label="Search"
                  placeholder="Filename search"
                  value={searchLine}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  />
          </Box>
          <EnhancedTableToolbar numSelected={selected.length} />

            </Stack>
            <SortableTable 
                rows={getFilteredRows()}
                onRowClick={props.onRowClick}
                onFIleDeleteHandler={props.onFIleDeleteHandler}
                searchLine={searchLine}
                onSearchLineChange={handleSearchChange}
                dense={dense}
                openDeleteDialog={openDeleteDialog}
                selected={selected}
                setSelected={setSelected}
                setOpenDeleteDialog={setOpenDeleteDialog}
              />

        </Paper>
        <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
        />
    </Box >  
  );
}

export default SortableTableWrapper;
