import { format } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
    if (date === undefined)
    {
      return "N/A"
    }

    return format(new Date(date), 'dd MMMM yyyy');
  }