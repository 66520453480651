import React from 'react';
import Alert from '@mui/material/Alert';


const CancellationNotification = ({ videoName, onClose }) => {
  return (
    <Alert severity="warning" onClose={onClose}>
        "{videoName}" was deleted during processing and is currently being removed from the system. This notification will disappear once the process is complete, but you may dismiss it at any time.
    </Alert>
  );
};

export default CancellationNotification;
